// Plugins
import "bootstrap/dist/js/bootstrap";
// import jquery from "jquery/dist/jquery";
let jquery = require("jquery");
window.$ = window.jQuery = jquery;

// import "../template/js/owlcarousel.min";
// import "owl.carousel"
let owl_carousel = require("owl.carousel");
window.fn = owl_carousel;
import "../template/js/jquery.colorbox";
// import "jquery-colorbox";
// import "../template/js/smoothscroll";
import "smoothscroll";
import "../template/js/custom";

// Funciones
import "./helpers";

// Components

// Enrutamiento del JavaScript
const importFront = {
    layout: require("./views/layout"),
    playVideo: require("../../backend/js/views/admin/videos/modals/play"),
    infoCategory: require("./views/infoCategory"),
};

const ElementFront = document.querySelectorAll("[data-current-section]");

export const loadFrontFunction = () => {
    // console.log("frontend");
    ElementFront.forEach((element) => {
        if (element) {
            let functionName = element.getAttribute("data-current-section");

            if (functionName in importFront) {
                importFront[functionName][functionName]();
            }
        }
    });
};

loadFrontFunction();
