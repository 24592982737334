export const playVideo = () => {
    document.addEventListener("click", (e) => {
        const Target = e.target.closest(".target");

        if (Target) {
            if (Target.classList.contains("video")) {
                showVideo(
                    Target.getAttribute("data-data"),
                    Target.getAttribute("data-url-video"),
                    Target.getAttribute("data-url-image")
                );
            }
        }
    });
};

export const showVideo = (data, urlVideo, urlImage) => {
    data = JSON.parse(atob(data));
    const PlayVideo = document.querySelector("#playVideo");
    const ModalTitle = PlayVideo.querySelector(".modal-title");
    const CntVideo = PlayVideo.querySelector("#cntVideo");
    ModalTitle.textConitent = data.name;

    CntVideo.innerHTML = "";

    if (data.video) {
        CntVideo.innerHTML = `<video class="img-fluid" src="${urlVideo}/${data.video}" controls></video>`;
    } else if (data.url) {
        CntVideo.innerHTML = data.url;
    } else {
        CntVideo.innerHTML = `<img class="img-fluid" src="${urlImage}/${data.image}">`;
    }
};
