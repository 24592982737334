import { bodyRequest, requestOptions } from "../helpers";

export const infoCategory = () => {
    // console.log("infoCategory");
    const CurrentSection = document.querySelector(
        "[data-current-section=infoCategory]"
    ); // Identificar la sección

    CurrentSection.addEventListener("click", (e) => {
        let order = e.target.getAttribute("order");
        if (order) {
            e.preventDefault();
            orderCategory(e.target.href, order);
        }
    });
};

const orderCategory = (url, order) => {
    bodyRequest.set("order", order);

    fetch(url, requestOptions)
        .then((response) => response.text())
        .then((data) => {
            const doc = new DOMParser().parseFromString(data, "text/html");

            for (const query of ["#posts"]) {
                const old = document.querySelector(query);
                const nuevo = doc.querySelector(query);
                old.parentNode.replaceChild(nuevo, old);
            }
        });
};
