import { alertHTML } from "../helpers";

export const layout = () => {
    // console.info("*-*-* Layout *-*-*");

    const Alert = document.querySelector("#alert");
    
    if (Alert) {
        let alerts = Alert.value;

        if (alerts) {
            alerts = JSON.parse(atob(alerts));
        }

        for (const key in alerts) {
            alertHTML(alerts[key], key, 7000);
        }
    } else {
        console.warn("No existe el elemento 'alert'");
    }
};
